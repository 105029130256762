import axios from 'axios'; // 引入axios
// import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据。
import { Message } from 'element-ui';
import router from '../router/index';
import store from '../store/index'; // 导入vuex,需要使用到里面的状态对象
import { baseURL } from '../setting'


export const request = axios.create({
    baseURL
})


//设置请求超时
// axios.defaults.timeout = 10000

//post请求头的设置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//请求拦截器
request.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // if (config.headers["Content-Type"] == "multipart/form-data") {

        // }
        // else {
        //     let data = config.data;
        //     // console.log(data,"en")
        //     // console.log(`%c接口  ${url}  `+data.message,`color:#00f;background:#${Math.random().toString(16).substr(-6)}5e`)
        //     if (data) {
        //       Object.keys(data).forEach(key => {
        //         if (typeof data[key] == "string") {
        //           data[key] = data[key].trim();
        //         }
        //       });
        //     }
        //     switch (config.method) {
        //       case "post":
        //         config.data = QS.stringify(data);
        //         break;
        //       default:
        //         break;
        //     }
        //   }
        const sessionId = sessionStorage.getItem("token");

        // config.data = config.data ? QS.stringify(config.data):{}

        if (sessionId) {
            sessionId && (config.headers.sessionId = sessionId);
        }
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

// 响应拦截器
request.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status >= 200 &&  response.status < 300) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });
                    break;
                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                case 403:
                    Message({
                        message: '登录过期，请重新登录',
                        duration: 1000,
                        type:'error'
                    });
                    // 清除token
                    sessionStorage.removeItem('token');
                    store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
                    break;

                    // 404请求不存在
                case 404:
                    Message({
                        message: '网络请求不存在',
                        duration: 1500,
                        type:'error'
                    });
                    break;
                    // 其他错误，直接抛出错误提示
                default:
                    Message({
                        message: error.response.data.message,
                        duration: 1500,
                        type:'error'
                    });
            }
            return Promise.reject(error.response);
        }
    }
);



/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
};


/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                if (res.data.code == 502) {
                    // Toast("登录失败");
                    router.push({ path: '/login' });
                } else {
                    resolve(res.data);
                }
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

