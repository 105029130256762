import { request } from "@/utils/http";
/* 登录接口 */
//获取短信验证码
export function getLoginYzm(data) {
    return request({
        url: `/biz/pc/getLoginYzm?phoneNumber`,
        method: 'post',  
        data
    })
}
//登录接口
export function doLogin(data) {
    return request({
        url: '/biz/pc/doLogin',
        method: 'post',
        data
    })
}
/* 我的报告 */
export function getPatientList(data) {
    return request({
        url: '/biz/patient/list',
        method: 'post',
        data
    })
}
//我的报告详情
export function getPatientDetail(data) {
    return request({
        url: `/biz/patient/detail?id=${data}`,
        method: 'get',
        data
    })
}
//收藏报告
export function Addfavourite(data) {
    return request({
        url: `/biz/favourite/add`,
        method: 'post',
        data
    })
}
//收藏报告详情  /biz/favourite/detail  POST   uuid
export function favouriteDetail(data) {
    return request({
        url: `/biz/favourite/detail`,
        method: 'post',
        data
    })
}
//取消收藏 /biz/favourite/delete
export function Deletefavourite(data) {
    return request({
        url: `/biz/favourite/delete`,
        method: 'post',
        data
    })
}
//导出 /biz/patient/export
export function patientExport(data) {
    return request({
        url: `/biz/patient/export?id=${data}`,
        method: 'get',
        responseType:'blob'
    })
}
//获取企业简介
export function getProfile(data) {
    return request({
        url: '/biz/brief/detail',
        method: 'post',
        data
    })
}
//获取收藏列表 /biz/favourite/list
export function favouriteList(data) {
    return request({
        url: `/biz/favourite/list`,
        method: 'post',
        data
    })
}
//获取个人信息 /biz/pc/getUser
export function getUser(data) {
    return request({
        url: `/biz/pc/getUser`,
        method: 'get',
        data
    })
}
//修改个人信息 /biz/pc/editUser
export function editUser(data) {
    return request({
        url: `/biz/pc/editUser`,
        method: 'post',
        data
    })
}
/* 我的检测 */
//detect页面获取列表  /biz/operation/projectNameTotal
export function projectNameTotal(data) {
    return request({
        url: `/biz/operation/projectNameTotal`,
        method: 'post',
        data
    })
}
//检测详情页面获取列表
export function projectItemTotal(data) {
    return request({
        url: `/biz/operation/projectItemTotal`,
        method: 'post',
        data
    })
}
//detectLine页面获取数据 /biz/operation/projectItemTrend
export function projectItemTrend(data) {
    return request({
        url: `/biz/operation/projectItemTrend`,
        method: 'post',
        data
    })
}
/* 我的设备 */
//获取设备列表信息 /biz/owner/device
export function ownerDevice(data) {
    return request({
        url: `/biz/owner/device`,
        method: 'post',
        data
    })
}
//曲线图接口
export function ownerProjectItemTrend(data) {
    return request({
        url: `/biz/owner/projectItemTrend`,
        method: 'post',
        data
    })
}
//设备详情
export function ownerProjectItemTotal(data) {
    return request({
        url: `/biz/owner/projectItemTotal`,
        method: 'post',
        data
    })
}
/* 搜索报告  /biz/pc/diseasesPage keyword关键词  patient_project_name   is_qc 0否 1是 from_time    to_time   */  
export function diseasesPage(data) {
    return request({
        url: `/biz/pc/diseasesPage`,
        method: 'post',
        data
    })
}
//下载excel  /biz/pc/diseasesExport
export function diseasesExport(data) {
    return request({
        url: `/biz/pc/diseasesExport?patient_project_name=${data}`,
        method: 'get',
        responseType: "blob",
    })
}
//我的检测导出统计导出 excel
export function operationStatExport(data) {
    return request({
        url: `/biz/operation/statExport`,
        method: 'post',
        responseType: "blob",
        data
    })
}
 
//我的设备导出统计导出 excel
export function ownerStatExport(data) {
    return request({
        url: `/biz/owner/statExport`,
        method: 'post',
        responseType: "blob",
        data
    })
}
